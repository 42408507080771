<template>
    <div class="search-select-wrapper">
        <div class="mb-3">
            <input type="text" class="form-control" :id="theid" placeholder="Search songs by title or artist" v-model="search">
        </div>
        <div class="search-results">
            <ul class="list-group">
                <li @click="selected(option)" v-for="(option,i) in options" :key="i" class="list-group-item">
                    {{ option.title }} - {{option.artist}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SearchSelect',
  data: () => ({
    search_string: ''
  }),
  computed: {
    search: {
      set (val) {
        this.search_string = val
        this.$emit('search', this.search_string)
      },
      get () {
        return this.search_string
      }
    }
  },
  watch: {
  },
  props: ['theid', 'options'],
  methods: {
    selected (option) {
      this.$emit('option:selected', option)
      this.search = ''
    }
  },
  created () {
  },
  mounted () {
  },
  components: {
  }
}
</script>
<style scoped>
</style>
