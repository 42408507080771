<template>
  <div class="page-layout-wrapper">
    <div class="container">
      <PageHeader
        title="All Playlists"
        :subtitle="subtitle"
      >
        <div class="mt-5">
          <button @click="newPlaylist" type="button" class="btn btn-primary">
            Create Playlist
          </button>
        </div>
      </PageHeader>


      <div class="mt-5 justify-content-center">
        <div v-if="loading" class="text-center">
          Loading Playlists...
        </div>
        <table
          v-else
          class="table"
        >
          <draggable
            tag="tbody"
            v-model="playlists"
            group="playlist_order"
            @end="savePlaylistOrder"
            handle=".drag-handle"
          >
            <tr
              v-for="(playlist, i) in playlists"
              :key="i"
            >
              <td>
                <img :src="playlist.thumbnail" class="playlist-image">
              </td>
              <td>
                <h6 v-text="playlist.title"/>
                <div class="small">
                  <router-link
                    :to="`/admin/users/${playlist.user.id}`"
                    v-text="playlist.user.email"
                  />
                </div>
              </td>
              <td>
                                <span
                                  v-if="playlist.status == 'public' || playlist.status == 'artist'"
                                  class="pill pill--green"
                                  v-text="'Public'"
                                />
              </td>
              <td>
                <div class="pill-button-group">
                  <a href="#" class="pill-button" @click.prevent="initEdit(playlist)">
                    Edit
                  </a>
                  <a href="#" class="pill-button drag-handle" @click.prevent="() => {}" title="Drag to reorder">
                    <i class="fas fa-grip-lines"></i>
                  </a>
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </div>

      <b-modal id="modal-create-playlist" ref="modal" title="Create Playlist" size="lg" centered hide-footer>
        <CreatePlaylist @playlist:created="onPlaylistCreated"></CreatePlaylist>
      </b-modal>

      <b-modal id="modal-edit-playlist" ref="modal" title="Edit Playlist" size="lg" centered hide-footer>
        <EditPlaylist :playlist="playlist"
                      @playlist:updated="onPlaylistUpdated"
                      @playlist:deleted="onPlaylistDeleted"
        ></EditPlaylist>
      </b-modal>
    </div>
  </div>
</template>

<script>
import EditPlaylist from '@/components/Dashboard/EditPlaylist'
import CreatePlaylist from '@/components/Dashboard/CreatePlaylist'
import PageHeader from '@/components/Page/PageHeader'
import draggable from 'vuedraggable'

export default {
  name: 'List',
  components: {
    EditPlaylist,
    CreatePlaylist,
    draggable,
    PageHeader
  },
  data: () => ({
    loading: false,
    playlists: [],
    playlist: null
  }),
  created () {
    this.loading = true

    this.$http
      .get('/admin/playlists')
      .then(({ data }) => {
        if (data.status == 'OK') {
          this.playlists = data.playlists
        }
      })
      .catch(err => {
        console.log(err)

        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops!',
          text: 'Something went wrong, check the console',
        })
      })
      .then(() => this.loading = false)
  },
  computed: {
    subtitle () {
      if (this.loading) {
        return ''
      }

      const totalPlaylists = this.playlists.length

      if (totalPlaylists > 0) {
        return totalPlaylists === 1 ? '1 Playlist Found' : `${totalPlaylists} Playlists Found`
      }

      return 'No Playlists Found'
    }
  },
  methods: {
    savePlaylistOrder () {
      this.$http
        .post('/admin/playlists/save-order', {
          playlists: this.playlists,
        })
        .then(({ data }) => {
          if (data.status == 'OK') {
            this.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Playlist order saved.',
              duration: 2000
            })
          }
        })
    },
    onPlaylistCreated (playlist) {
      this.playlists.push(playlist)
      this.$bvModal.hide('modal-create-playlist')
    },
    onPlaylistDeleted (playlist) {
      let index = this.playlists.findIndex(({ id }) => id == playlist.id)

      if (index > -1) {
        this.playlists.splice(index, 1);
      }

      this.playlist = false
      this.$bvModal.hide('modal-edit-playlist')
    },
    onPlaylistUpdated () {
      this.$bvModal.hide('modal-edit-playlist')
    },
    newPlaylist () {
      this.$bvModal.show('modal-create-playlist')
    },
    initEdit (pl) {
      this.playlist = pl
      this.$bvModal.show('modal-edit-playlist')
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  tbody {
    td:first-child {
      width: 72px;
    }

    td:nth-child(3) {
      width: 80px;
      font-size: 95%;
    }

    td:last-child {
      width: 110px;
      padding-right: 0;
    }
  }

  td {
    vertical-align: middle;
    background-color: white; /* Needed so it doesn't look weird when we drag */
  }

  h6 {
    margin-bottom: 0.15rem;
  }
}

.playlist-image {
  width: 100%;
}

.drag-handle {
  cursor: grab;
}

.pill-button-group {
  justify-content: flex-end;
}
</style>
