<template>
  <form @submit.prevent="onFormSubmit">
    <div class="form-group">
      <label for="create_playlist_title">Playlist Name</label>
      <input type="text" class="form-control" id="create_playlist_title" v-model="pl.title">
    </div>
    <div class="form-group">
      <div class="custom-control custom-radio">
        <input class="custom-control-input" type="radio" name="create_playlist_status"
               id="create_playlist_status_public" value="public" v-model="pl.status">
        <label class="custom-control-label" for="create_playlist_status_public">
          Public
        </label>
      </div>
      <div class="custom-control custom-radio">
        <input class="custom-control-input" type="radio" name="create_playlist_status"
               id="create_playlist_status_private" value="private" v-model="pl.status">
        <label class="custom-control-label" for="create_playlist_status_private">
          Private
        </label>
      </div>
      <div class="custom-control custom-radio">
        <input class="custom-control-input" type="radio" name="create_playlist_status"
               id="create_playlist_status_artist" value="artist" v-model="pl.status">
        <label class="custom-control-label" for="create_playlist_status_artist">
          Artist
        </label>
      </div>
    </div>
    <h5>Add Songs</h5>
    <div class="form-group">
      <label for="playlist_song_search" class="sr-only">Find Songs</label>
      <v-select
        :options="song_options"
        @option:selected="songOptionSelected"
        @search="fetchSongOptions"
        label="title"
      >
        <template #selected-option="{ title, artist }">
          {{ title }} - {{ artist }}
        </template>
        <template v-slot:option="option">
          {{ option.title }} - {{ option.artist }}
        </template>
      </v-select>
    </div>

    <Loader
      v-if="loading.songs"
      :minheight="true"
    />
    <ol v-else class="list-group">
      <draggable
        v-model="songs"
        group="playlist_create"
      >
        <li
          v-for="(song, i) in songs"
          :key="i"
          class="list-group-item"
          handle=".drag-handle"
        >
          <div class="row align-items-center">
            <div class="col">
              <div class="d-flex align-items-center">
                <span v-text="`${i + 1}.`" style="padding-right: 5px;"/>
                <h6 v-text="song.title" class="mb-0"/>
              </div>
            </div>
            <div class="col">
              <span v-text="song.artist"/>
            </div>
            <div class="col">
              <div class="pill-button-group justify-content-end">
                <router-link
                  target="_blank"
                  :to="'/admin/songs/'+song.song_id"
                  class="pill-button"
                >
                  Edit
                </router-link>
                <a href="#" class="pill-button" @click.prevent="removeSong(song)">
                  Remove
                </a>
                <a href="#" class="pill-button drag-handle" @click.prevent="() => {}" title="Drag to reorder">
                  <i class="fas fa-grip-lines"></i>
                </a>
              </div>
            </div>
          </div>
        </li>
      </draggable>
    </ol>

    <div class="mt-4">
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="loading.create"
        v-text="loading.create ? 'Saving...' : 'Save'"
      />
    </div>
  </form>
</template>

<script>
import draggable from 'vuedraggable'
import Loader from '@/components/Loader.vue'

export default {
  name: 'CreatePlaylist',
  components: {
    Loader,
    draggable
  },
  data: () => ({
    pl: {
      title: '',
      description: null,
      status: 'private'
    },
    songs: [],
    song_options: [],
    loading: {
      songs: false,
      create: false
    }
  }),
  methods: {
    getSongIndex (song) {
      return this.songs.findIndex(({ song_id }) => song.song_id == song_id)
    },
    removeSong (song) {
      const index = this.getSongIndex(song)

      if (index > -1) {
        this.songs.splice(index, 1)
      }
    },
    songOptionSelected (selectedOption) {
      this.loading.songs = true

      this.$http
        .get('/admin/songs/song/' + selectedOption.song_id)
        .then(({ data }) => {
          if (data.status == 'OK') {
            const song = data.song
            song.song_id = song.id
            const index = this.getSongIndex(song)
            if (index < 0) {
              this.songs.push(song)
            }
          } else {
            this.triggerErrorNotification()
          }

          this.loading.songs = false
        })
        .catch(err => {
          this.triggerErrorNotification(err)
          this.loading.songs = false
        })
    },
    fetchSongOptions (search, loading) {
      loading(true)

      this.$http.post('/admin/songs/search-options/', {
        search_string: search
      })
        .then(response => {
          if (response.data.status === 'OK') {
            this.song_options = response.data.songs.map(song => {
              song.song_id = song.id
              return song
            })
          }
          loading(false)
        })
        .catch(err => {
          this.triggerErrorNotification(err)

          loading(false)
        })
    },
    getSongs () {
      this.$http.get('/admin/playlists/songs/' + this.pl.id)
        .then(response => {
          if (response.data.status === 'OK') {
            this.songs = response.data.songs.map(song => {
              song.song_id = song
              return song
            })
          }
        })
        .catch(err => {
          this.triggerErrorNotification(err)
        })
    },
    create () {
      this.loading.create = true

      this.$http.post('/admin/playlists/create', {
        playlist: this.pl,
        songs: this.songs
      })
        .then(response => {
          if (response.data.status == 'OK') {
            this.$vs.notification({
              position: 'top-center',
              title: 'Success',
              text: 'Playlist was created'
            })
          }

          this.loading.create = false
          this.$emit('playlist:created', response.data.playlist)
          this.pl = {
            title: '',
            description: null,
            status: 'private'
          }
        })
        .catch(err => {
          this.triggerErrorNotification(err)
          this.loading.create = false
        })
    },
    triggerErrorNotification (err) {
      err && console.log(err)

      this.$vs.notification({
        position: 'top-center',
        title: 'Whoops!',
        text: 'Something went wrong, check the console'
      })
    },
    onFormSubmit () {
      this.create()
    }
  }
}
</script>

<style scoped>
.drag-handle {
  cursor: grab;
}
</style>
