<template>
    <form
        v-if="pl"
        @submit.prevent="onFormSubmit"
    >
        <div class="form-group">
            <label for="playlist_title">Name</label>
            <input type="text" class="form-control" id="playlist_title" v-model="pl.title">
        </div>
        <div class="form-group">
            <label for="playlist_user">Creator</label>
            <input disabled type="text" class="form-control" id="playlist_user" v-model="pl.user.email">
        </div>
        <div class="form-group">
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="playlist_status" id="playlist_status_private" value="private" v-model="pl.status">
                <label class="custom-control-label" for="playlist_status_private">
                    Private
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="playlist_status" id="playlist_status_public" value="public" v-model="pl.status">
                <label class="custom-control-label" for="playlist_status_public">
                    Public
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="create_playlist_status" id="create_playlist_status_artist" value="artist" v-model="pl.status">
                <label class="custom-control-label" for="create_playlist_status_artist">
                  Artist
                </label>
            </div>
        </div>

        <div class="mt-3">
            <h5>Photo</h5>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <img :src="pl.thumbnail" class="img-fluid">
                    </div>
                </div>

                <div v-if="edit_photo" class="col-9">
                    <div class="form-group">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" name="playlist_thumbnail" id="playlist_thumbnail_url" value="url" v-model="thumbnail_edit">
                            <label class="custom-control-label" for="playlist_thumbnail_url">
                                Url
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" name="playlist_thumbnail" id="playlist_thumbnail_upload" value="upload" v-model="thumbnail_edit">
                            <label class="custom-control-label" for="playlist_thumbnail_upload">
                                Upload
                            </label>
                        </div>
                    </div>

                    <div v-if="thumbnail_edit == 'url'" class="form-group">
                        <label for="playlist_thumbnail" class="sr-only">Playlist Photo</label>
                        <input type="text" class="form-control" id="playlist_thumbnail" v-model="pl.thumbnail">
                    </div>

                    <div v-if="thumbnail_edit == 'upload'" class="form-group">
                        <label for="playlist_thumbnail_file" class="sr-only">Playlist Photo</label>
                        <input type="file" ref="playlist_file_upload" class="form-control-file" id="playlist_thumbnail_file">
                    </div>

                    <button class="btn btn--sm" @click.prevent="edit_photo = false">Cancel</button>
                </div>
                <div v-else class="col-9">
                    <button class="btn btn--sm" @click.prevent="edit_photo = true">Edit Photo</button>
                </div>
            </div>
        </div>

        <div class="mt-3">
          <h5>Description</h5>
          <div class="form-group">
            <b-form-textarea
            id="playlist-description"
            v-model="pl.description"
            rows="3"
            max-rows="6"
            maxlength="1000"></b-form-textarea>
          </div>
        </div>

        <div class="mt-3 mb-5">
            <h5>Songs</h5>

            <div class="form-group">
                <label for="playlist_song_search" class="sr-only">Find Songs</label>
                <SearchSelect
                    theid="playlist_song_search"
                    :options="song_options"
                    @search="fetchSongOptions"
                    @option:selected="songOptionSelected"
                />
                <!--
                <v-select @option:selected="songOptionSelected" :options="song_options" @search="fetchSongOptions" label="title">
                    <template #selected-option="{ title, artist }">
                        <strong>{{ title }} - {{artist}}</strong>
                    </template>
                    <template v-slot:option="option">
                        {{ option.title }} - {{option.artist}}
                    </template>
                </v-select>
                -->
            </div>
            <Loader
                v-if="loading.songs"
                :minheight="true"
            />
            <ol v-else class="list-group">
                <draggable
                    v-model="songs"
                    group="playlist_edit"
                >
                    <li
                        v-for="(song, i) in songs"
                        :key="i"
                        class="list-group-item"
                        handle=".drag-handle"
                    >
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="d-flex align-items-center">
                                    <span v-text="`${i + 1}.`" style="padding-right: 5px;" />
                                    <h6 v-text="song.title" class="mb-0" />
                                </div>
                            </div>
                            <div class="col">
                                <span v-text="song.artist" />
                            </div>
                            <div class="col">
                                <div class="pill-button-group justify-content-end">
                                    <router-link
                                        target="_blank"
                                        :to="'/admin/songs/'+song.song_id"
                                        class="pill-button"
                                    >
                                        Edit
                                    </router-link>
                                    <a href="#" class="pill-button" @click.prevent="removeSong(song)">
                                        Remove
                                    </a>
                                    <a href="#" class="pill-button drag-handle" @click.prevent="() => {}" title="Drag to reorder">
                                        <i class="fas fa-grip-lines"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </draggable>
            </ol>
        </div>
        <div class="mt-4">
            <button
                type="submit"
                class="btn btn-primary"
                :disabled="loading.update"
                v-text="loading.update ? 'Saving...' : 'Save'"
            />

            <button
                @click.prevent="deleteWithConfirmation"
                type="button"
                class="btn btn-danger"
                :disabled="loading.update"
                v-text="'Delete'"
            />
        </div>
    </form>
</template>

<script>
import Swal from 'sweetalert2'
import Loader from '@/components/Loader.vue'
import draggable from 'vuedraggable'
import Vue from 'vue'
import SearchSelect from '@/components/SearchSelect.vue'

export default {
  name: 'EditPlaylist',
  components: {
    Loader,
    draggable,
    SearchSelect
  },
  data: () => ({
    search_timeout: false,
    pl: false,
    edit_photo: false,
    thumbnail_edit: 'url',
    songs: [],
    song_options: [],
    loading: {
      songs: false,
      update: false
    }
  }),
  created () {
    this.init(this.playlist)
  },
  watch: {
    playlist (val) {
      this.init(val)
    }
  },
  props: ['playlist'],
  methods: {
    init (pl) {
      this.pl = pl
      if (pl) {
        this.getSongs()
      }
    },
    removeSong (song) {
      const index = this.songs.findIndex(({ song_id }) => song.song_id == song_id)

      if (index > -1) this.songs.splice(index, 1)
    },
    songOptionSelected (selectedOption) {
      this.loading.songs = true
      const that = this
      this.$http.get('/admin/songs/song/' + selectedOption.song_id)
        .then(response => {
          if (response.data.status == 'OK') {
            const index = that.songs.findIndex((song) => { return song.song_id === response.data.song.song_id })
            if (index < 0) {
              const song = response.data.song;
              song.song_id = song.id;
              that.songs.push(song)
            }
          } else {
            that.$vs.notification({
              position: 'top-center',
              title: 'Whoops, something went wrong.'
            })
          }
          Vue.set(that, 'song_options', [])
          that.loading.songs = false
        })
        .catch(err => {
          that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
          that.loading.songs = false
        })
    },
    doFetchSongOptions (search) {
      const that = this
      this.$http.post('/admin/songs/search-options/', {
        search_string: search
      })
        .then(response => {
          if (response.data.status == 'OK') {
            Vue.set(that, 'song_options', response.data.songs.map(song => {
              // TODO: This is a hack until the API gets rid of song_id
              song.song_id = song.id;

              return song;
            }))
          }
        })
        .catch(err => {
          that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
        })
    },
    fetchSongOptions (search) {
      const that = this
      clearTimeout(this.search_timeout)
      if (!search || search.trim() == '') {
        return
      }
      this.search_timeout = setTimeout(function () {
        that.doFetchSongOptions(search)
      }, 500)
    },
    getSongs () {
      this.$http
        .get('/admin/playlists/songs/' + this.pl.id)
        .then(({ data }) => {
          if (data.status == 'OK') {
            this.songs = data.songs.map(song => {
              // TODO: This is a hack until the API gets rid of song_id
              song.song_id = song.id;

              return song;
            });
          }
        })
        .catch(err => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
        })
    },
    update () {
      const that = this
      that.loading.update = true
      const formData = new FormData()
      formData.append('playlist', JSON.stringify(that.pl))
      formData.append('songs', JSON.stringify(that.songs))
      formData.append('edit_photo', that.edit_photo)
      let photo = false
      if (that.edit_photo && that.thumbnail_edit == 'upload' && this.$refs.playlist_file_upload.files.length) {
        photo = this.$refs.playlist_file_upload.files[0]
        formData.append('photo', photo)
      }
      this.$http.post('/admin/playlists/update', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.status == 'OK') {
            that.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Playlist was updated'
            })
          }
          that.$emit('playlist:updated', that.pl)
          that.loading.update = false
        })
        .catch(err => {
          that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
          that.loading.update = false
        })
    },
    deletePlaylist () {
      this.loading.update = true

      this.$http
        .post('/admin/playlists/delete', {
          playlist: this.pl
        })
        .then(({ data }) => {
          if (data.status == 'OK') {
            this.$vs.notification({
              position: 'top-center',
              title: 'Success',
              text: 'Playlist was deleted'
            })

            this.$emit('playlist:deleted', this.pl)
          }
        })
        .catch(err => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })

          console.log(err)
        })
        .then(() => this.loading.update = false)
    },
    deleteWithConfirmation (e) {
      Swal.fire({
        title: 'Delete Playlist?',
        text: 'Are you sure you want to delete this playlist?',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.deletePlaylist()
        }
      })
    },
    onFormSubmit () {
      this.update()
    }
  }
}
</script>
<style scoped>
.drag-handle {
    cursor: grab;
}
</style>
